





































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ProviderSelection extends Vue {
    
    @Prop() network_providers;

    providers = [];
    selected_providers = [];

    mounted(){
        this.updateProviders();
    }

    @Watch('network_providers')
    updateProviders(){
        this.providers = Object.values(this.network_providers);
    }

    updateSelectedProviders(){
        const providers_name = this.selected_providers.map((provider) => provider.provider);
        this.$emit('providersChanged', providers_name);
    }
}

