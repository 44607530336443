


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { User, Global } from '@/store';
import ListDialog from '@/components/ListDialog.vue';
import RecreateData from './RecreateData.vue';
import PropUtils from '@/modules/PropUtils';
import ProviderSelection from './ProviderSelection.vue';

@Component({
  components: { ListDialog, RecreateData, ProviderSelection }
})
export default class NetworkToolbar extends Vue {
  @Prop() projectProperties;
  @Prop() network_providers;
  @Prop() selected_providers;
  @Prop() last_time_update;
  @Prop() filters;
  @Prop() not_commissioned;
  @Prop() sai_req_fault;
  @Prop() communicated;
  @Prop() three_days_missing;
  @Prop() never_communicated;
  @User.State('project') project;
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;

  clicked = {
    communicated: true,
    three_days_missing: false,
    never_communicated: false,
    not_commissioned: false,
    sai_req_fault: false
  };
  loading = false;
  items = [];
  devices_to_select = [];
  search = null;
  select = null;

  mounted() {
    this.devices_to_select = this.communicated.map((device) => ({
      id: device.id,
      name: device.name,
      group: 'communicated',
      poleNumber: device['meta.device'].pole_number || null
    }));
  }

  updateSelectedProviders(providers) {
    this.$emit('providersChanged', providers);
  }

  handleFilterClicked(filter) {
    this.clicked[filter.id] = !this.clicked[filter.id];
    if (filter.id !== 'not_commissioned') {
      this.$emit('mapDevicesChanged', this[filter.id], this.clicked[filter.id]);
      this.updateSearchList();
    }
  }

  closeUncommissionedDialog() {
    this.clicked.not_commissioned = false;
  }

  setDeviceClicked(device) {
    const device_data = this[device.group].find(
      (device_group) => device_group.id === device.id
    );
    this.$emit('selectDevice', device_data);
    this.$emit('panTo', device_data);
    this.$emit('popupChanged', device_data);
  }

  querySelections(v) {
    this.loading = true;
    // Simulated ajax query
    setTimeout(() => {
      this.items = this.devices_to_select.filter((device) => {
        return (
          (device.name || '').toLowerCase().indexOf((v || '').toLowerCase()) >
            -1 ||
          (device.poleNumber !== undefined &&
            (device.poleNumber || '')
              .toLowerCase()
              .indexOf((v || '').toLowerCase()) > -1)
        );
      });
      this.loading = false;
    }, 500);
  }

  @Watch('search')
  filterItems(val) {
    if (val && val !== this.select) {
        this.querySelections(val);
    }
  }

  updateSearchList() {
    this.devices_to_select = [];
    Object.entries(this.clicked)
      .filter(([group, clicked]) => clicked)
      .forEach(([group, clicked]) => {
        this.devices_to_select = this.devices_to_select.concat(
          this[group].map((device) => ({
            id: device.id,
            name: device.name,
            group,
            poleNumber: device['meta.device'].pole_number
          }))
        );
      });
  }

  getItemText(item) {
    return `${item.name} ${item.poleNumber}`;
  }
}
