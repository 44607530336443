
































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ListDialog extends Vue {
    @Prop() showList;
    @Prop() dialog_title;
    @Prop() items;

    handleCloseClicked(){
      this.$emit('dialogClosed');
    }
}

